/*--------------------------------------------------------------
22. wpo-contact-pg-section
--------------------------------------------------------------*/
.wpo-contact-pg-section {
  padding-bottom: 0px;

  .wpo-contact-title {
    max-width: 440px;
    margin: 0 auto;
    text-align: center;
    margin-bottom: 50px;

    @media (max-width: 767px) {
      margin-bottom: 30px;
    }

    h2 {
      font-size: 35px;
      font-size: calc-rem-value(35);
      font-weight: 400;
      margin-bottom: 30px;

      @media (max-width: 575px) {
        font-size: 25px;
        font-size: calc-rem-value(25);
      }
    }
  }

  .form-field {
    margin-bottom: 30px;
  }

  .errorMessage {
    margin-top: 5px;
    color: red;
    font-size: 14px;
  }

  form input,
  form select,
  form textarea {
    background: transparent;
    width: 100%;
    height: 50px;
    border: 1px solid transparent;
    border-radius: 0px;
    box-shadow: none !important;
    padding-left: 25px;
    border: 1px solid #ebebeb;

    @include media-query(991px) {
      height: 45px;
    }

    &:focus {
      border-color: $theme-primary-color;
      background: transparent;
      box-shadow: none;
      outline: none;
    }
  }

  form textarea {
    height: 180px;
    padding-top: 15px;
  }

  form {
    margin: 0 -15px;
    overflow: hidden;

    @include placeholder-style(#9d9c9c, 15px, normal);

    select {
      display: inline-block;
      color: #a9a9a9;
      cursor: pointer;
      opacity: 1;
      padding: 6px 25px;
      font-size: 15px;
      font-size: calc-rem-value(15);
      -webkit-appearance: none;
      -ms-appearance: none;
      -o-appearance: none;
      appearance: none;
      -moz-appearance: none;
      background: transparent url(../../images/select-icon2.png) no-repeat
        calc(100% - 15px) center;
      position: relative;

      &:focus {
        background: transparent url(../../images/select-icon2.png) no-repeat
          calc(100% - 15px) center;
      }
    }

    .submit-area {
      text-align: center;
      width: 100%;
      margin-bottom: 10px;
      margin-left: 0;

      @include media-query(767px) {
        margin-bottom: 0;
      }

      .theme-btn {
        border-radius: 0px;
        font-family: $base-font;
        font-size: 16px;

        &:after {
          border-radius: 0px;
        }
      }
    }
  }

  form > div {
    margin: 0 15px 25px;

    @include media-query(600px) {
      width: calc(100% - 25px);
      float: none;
    }
  }

  form .fullwidth {
    width: calc(100% - 25px);
    float: none;
    clear: both;
  }

  .office-info {
    padding-bottom: 100px;

    @media (max-width: 767px) {
      padding-bottom: 60px;
    }

    .col {
      .office-info-item {
        @media (max-width: 1200px) {
          margin-bottom: 30px;
        }
      }

      &:last-child {
        .office-info-item {
          @media (max-width: 1200px) {
            margin-bottom: 0px;
          }
        }
      }
    }

    .office-info-item {
      text-align: center;
      box-shadow: 0px 5px 15px 0px rgba(62, 65, 159, 0.1);
      padding: 20px;

      .office-info-icon {
        width: 85px;
        height: 85px;
        background: #f5f5f5;
        line-height: 85px;
        text-align: center;
        margin: 0 auto;
        @include rounded-border(50%);

        .icon {
          .fi {
            &:before {
              font-size: 35px;
              color: $theme-primary-color-s2;
            }
          }
        }
      }

      .office-info-text {
        h2 {
          font-size: 30px;
          font-weight: 500;
          margin: 20px 0;
          font-family: $base-font;
        }

        p {
          margin-bottom: 0;
        }
      }
    }
  }

  .wpo-contact-form-area {
    padding: 50px;
    background: $white;
    box-shadow: 0px 1px 15px 0px rgba(62, 65, 159, 0.1);
    margin-bottom: -125px;
    position: relative;
    z-index: 99;

    @media (max-width: 767px) {
      padding: 30px;
      padding-top: 50px;
    }
  }
}

.wpo-contact-map-section {
  .wpo-contact-map {
    height: 550px;
  }

  iframe {
    width: 100%;
    height: 100%;
    border: 0;
    outline: 0;
  }

  h2.hidden {
    display: none;
  }
}

.wpo-contact-section,
.wpo-contact-section-s2,
.wpo-contact-section-s3,
.wpo-contact-section-s4,
.wpo-contact-section-s5 {
  .col {
    padding: 0;

    @media (max-width: 767px) {
      padding: 0 15px;
    }
  }

  .contact-img {
    img {
      width: 100%;
      min-height: 600px;
      object-fit: cover;

      @media (max-width: 767px) {
        min-height: unset;
      }
    }
  }

  .wpo-contact-section-wrapper {
    position: relative;
    margin: 0 auto;
    background: darken($theme-primary-color, 1);
    z-index: 10;

    .wpo-section-title {
      h4 {
        color: $white;
        font-size: 30px;
        line-height: 35px;
        margin-bottom: 0;

        @media (max-width: 767px) {
          font-size: 25px;
        }

        @media (max-width: 575px) {
          font-size: 18px;
        }
      }

      h2 {
        color: $white;
      }

      @media (max-width: 1200px) {
        margin-bottom: 20px;
      }
    }

    .vector-1 {
      position: absolute;
      left: 0;
      top: 0;
      z-index: -1;

      @media (max-width: 575px) {
        display: none;
      }
    }

    .vector-2 {
      position: absolute;
      right: 0;
      bottom: 0;
      z-index: -1;

      @media (max-width: 575px) {
        display: none;
      }
    }

    .wpo-contact-form-area {
      padding: 100px 40px;
      position: relative;
      z-index: 99;
      background: transparent;

      @media (max-width: 1600px) {
        padding: 60px 30px;
      }

      @media (max-width: 1199px) {
        padding: 60px 20px;
      }

      @media (max-width: 575px) {
        border-width: 10px;
        padding: 20px 10px;
      }

      .errorMessage {
        color: red;
        font-size: 16px;
        margin-top: -10px;
      }

      .form-control,
      .react-datepicker-wrapper input {
        width: 100%;
        height: 50px;
        margin-bottom: 20px;
        border: 0;
        border-bottom: 1px solid #edccc1;
        background: transparent;
        border-radius: 0;
        color: $white;
        color-scheme: $white;
        font-size: 18px;

        &:focus {
          outline: none;
          box-shadow: none;
        }

        &::-webkit-calendar-picker-indicator {
          filter: invert(1);
        }
      }

      .form-control::-webkit-input-placeholder {
        /* Edge */
        color: $white;
      }

      .form-control:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: $white;
      }

      .form-control::placeholder {
        color: $white;
      }

      select.form-control {
        color: $white;
        -webkit-appearance: none;
        -ms-appearance: none;
        -o-appearance: none;
        appearance: none;
        -moz-appearance: none;
        background: transparent url(../../images/select-icon.png) no-repeat
          calc(100% - 15px) center;
        position: relative;

        option {
          color: $text-color;
        }
      }

      .react-datepicker-wrapper {
        input {
          padding: 0.375rem 0.75rem;
          -webkit-appearance: none;
          -ms-appearance: none;
          -o-appearance: none;
          appearance: none;
          -moz-appearance: none;
          background: transparent url(../../images/date.png) no-repeat
            calc(100% - 15px) center;
        }
      }

      .select.last {
        margin-bottom: 50px;
      }

      .submit-area {
        margin-top: 40px;
        text-align: center;

        .theme-btn {
          background: $white;
        }
      }
    }
  }
}
